import React, { useState } from 'react';

import { API } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import FormField from './form-field';

import './registration.css';

const validatorFunctions = {
  'required': (name, value) => !value ? { error: `${name} is required` } : undefined,
  'email': (name, value) => !value || !value.match(/^([^@]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,50})$/)
    ? { error: `${name} must be a valid email address` }
    : undefined,
};

const validators = [
  { field: 'teamName', label: 'Team name', validators: ['required'] },
  { field: 'email', label: 'Email', validators: ['required', 'email'] },
  { field: 'firstName', label: 'First name', validators: ['required'] },
  { field: 'lastName', label: 'Last name', validators: ['required'] },
];

const validate = (details) => {
  const errors = validators.reduce((errors, { field, label, validators: fieldValidators }) => {
    const [firstError] = fieldValidators.reduce((fieldErrors, validator) => {
      const error = validatorFunctions[validator]((label || field), details[field]);

      if (error) {
        return [...fieldErrors, error];
      }

      return fieldErrors;
    }, []);

    if (firstError) {
      errors[field] = firstError;
    }

    return errors;
  }, {});

  return errors;
};

export default () => {
  const [details, setDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    const validationErrors = validate(details);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length) {
      return;
    }

    setLoading(true);

    try {
      await API.post('api', `register`, {
        body: {
          teamName: details.teamName,
          owner: {
            email: details.email,
            firstName: details.firstName,
            lastName: details.lastName,
          },
        },
      });

      document.location = `${process.env.REACT_APP_LOGIN_LOCATION}`;
    } catch (e) {
      setLoading(false);

      if (e.message === 'Network Error') {
        setErrors({ general: { error: 'Could not create team at this time, please check your network connection and try again' } });
        return;
      }

      if (e.response.status === 400) {
        if (!e.response.data || !e.response.data.code) {
          setErrors({ general: { error: 'Could not create team at this time, please contact support' } });
          return;
        }

        if (e.response.data.code === 'TEAM_NAME_UNAVAILABLE') {
          setErrors({ teamName: { error: 'Sorry, this team name is not available' } });
          return;
        }

        setErrors({ general: { error: 'Could not create team at this time, please contact support' } });
        return;
      }
    }
  };

  const valueChange = ({ key, value }) => {
    const newDetails = { ...details };

    newDetails[key] = value;

    setDetails(newDetails);
  };

  if (loading) {
    return <div className="loading__container">
      <div className="loading__spinner">
        <FontAwesomeIcon icon={faSpinner} />
      </div>
      <div className="loading__text">
        Creating your team...
      </div>
    </div>;
  }

  const fieldProps = {
    valueChange,
    submit,
    errors,
    details,
  };

  return <div className="registration-form">
    <div className="registration-form__header">
      Sign up for a free account and get 10 reports per month for no fee.
    </div>
    {errors && errors.general ? <div className="registration-form__errors">
      <div className="registration-form__errors__icon">
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </div>
      <div className="registration-form__errors__text">
        {errors.general.error}
      </div>
    </div> : <></>}
    <div>
      <FormField label="Team Name" {...fieldProps} fields={[{ name: 'teamName' }]} />
      <FormField label="Your Name" {...fieldProps} fields={[
        { placeholder: 'First', name: 'firstName' },
        { placeholder: 'Last', name: 'lastName' },
      ]} />
      <FormField label="Your Email" {...fieldProps} fields={[{ name: 'email' }]} />
    </div>

    <div className="registration-form__buttons">
      <button onClick={submit} className="button">
        <div className="button__icon">
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <div className="button__text">Get Started Now!</div>
      </button>
    </div>
  </div>;
};
