import React from 'react';

export default ({ label, fields, valueChange, details, errors, submit }) => {
  const onChange = (e) => {
    const value = e.target.value;

    valueChange({
      key: e.target.name,
      value,
    });
  };

  const onKeyDown = (e) => {
    if (e.keyCode !== 13) {
      return;
    }

    submit();
  };
 
  const fieldNames = fields.map(({ name }) => name);
  const fieldErrors = Object.entries(errors).filter(([key]) => fieldNames.includes(key));
  const formFieldClasses = `registration-form__field${fieldErrors.length ? ' registration-form__field_error' : ''}`;
  const errorMessage = fieldErrors.length
    ? <div className="registration-form__field__label__error">{fieldErrors.map(([, { error }]) => <>{error}<br/></>)}</div>
    : <></>;

  return <div className={formFieldClasses}>
    <label className="registration-form__field__label">
      {label}
      {errorMessage}
    </label>
    <div className="registration-form__field__fields">
      {fields.map(({ name, ...props }) => <div>
        <input type="text"
          className={errors[name] ? 'error' : ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          name={name}
          value={details[name] || ''}
          {...props} />
      </div>)}
    </div>
  </div>;
};
