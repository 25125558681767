import React from 'react';

import Amplify from "aws-amplify";

import Header from './components/header';
import RegistrationForm from './components/registration-form';

import './App.css';

Amplify.configure({
  Analytics: {
    disabled: true,
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
      },
    ],
  },
});

function App() {
  return (<>
      <Header />
      <div className="App">
        <header className="App-header">
          
          <RegistrationForm />
        </header>
      </div>
    </>
  );
}

export default App;
